<template>
    <div class="layout pt-0">
        <applicant-confirm-dob
            v-if="needsDOBValidation"
            :is-priority-application="this.isPriorityApplication"
            @dobconfirmed="onDOBConfirmed"
            @continuecurrent="onContinueCurrent"
        />
        <onboarding-layout
            v-else
            :error-text="errorText"
            :loading="loading"
        >
            <h5 class="fw-light mb-5">
                <strong>Welcome back!</strong><br>Would you like to continue with a previous application you started?
            </h5>
            <div class="d-grid">
                <button
                    class="btn btn-primary mb-2"
                    @click="onContinuePrior"
                >
                    Yes, Continue Previous
                </button>
            </div>
            <div class="d-grid">
                <button
                    class="btn btn-secondary mb-2"
                    @click="onContinueCurrent"
                >
                    No, Continue New Application
                </button>
            </div>
            <p class="text-muted mt-0">
                By continuing with your current application, the previous one will be closed.
            </p>
        </onboarding-layout>
    </div>
</template>

<script>
    import { abandonApplication } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import OnboardingLayout from '@/layouts/Onboarding'
    import { sharedPagePaths } from '@/routes/sharedRoutes'
    import { logger } from '@/utils/logger'
    import { LoanApplicationStatus } from '@/utils/constants'
    import ApplicantConfirmDob from '../../components/ApplicantConfirmDob'
    import priorApplicationMixin from '../../mixins/priorApplicationMixin'
    import { appSessionStorage, localStorageKey } from '../../utils/storage'

    export default {
        components: {
            OnboardingLayout,
            ApplicantConfirmDob,
        },
        mixins: [priorApplicationMixin],
        data() {
            return {
                pathAfterNextOne: this.$route.query.a,
                isCoApplicant: this.$route.query.c,
            }
        },
        mounted: async function () {
            try {
                if ((!this.returnToken2 && !this.needsDOBValidation) || this.pathAfterNextOne === undefined || this.isCoApplicant === undefined || this.priorStatus === undefined) {
                    logger.fatal(`Missing returnToken2 ${this.returnToken2} or pathAfterNextOne ${this.pathAfterNextOne} or isCoApplicant ${this.isCoApplicant}. Routing to thanks page`)
                    return await this.$router.push({ path: sharedPagePaths.THANKS })
                }

                if (!this.needsDOBValidation) {
                    await this.handleSpecialCases()
                }
            } catch (e) {
                this.errorText = ApiErrorHandler(e)
            }

            this.loading = false
            this.$logEvent('view_prompt_continue_prior_application')
        },
        methods: {
            handleSpecialCases: async function () {
                this.loading = true
                try {
                    if (this.isPriorityApplication) {
                        await this.redirectToPriorityApplication(this.returnToken2)
                        return true
                    } else if (this.priorStatus === LoanApplicationStatus.pending) {
                        await this.handlePendingPriorApplication()
                        return true
                    }
                    return false
                } finally {
                    this.loading = false
                }
            },
            handlePendingPriorApplication: async function () {
                logger.info(`Prior application is pending, automatically continuing with the current application`)
                await abandonApplication({ wantsToAbandonCurrentApplication: false, returnToken2ForPriorApplication: this.returnToken2, isCoApplicant: this.isCoApplicant })
                return await this.$router.replace(this.pathAfterNextOne)
            },
            redirectToPriorityApplication: async function () {
                logger.info('Priority application found, redirecting immediately')
                return await this.$router.replace({
                    path: sharedPagePaths.PRIORITY_APPLICATION_REDIRECTION,
                    query: {
                        p: this.returnToken2,
                    },
                })
            },
            updateLocalStorageInfo: async function (returnToken2) {
                if (typeof returnToken2 !== 'string' || returnToken2.length === 0) {
                    return
                }
                console.info('DOB successfully confirmed, updating session storage with new values')
                this.returnToken2 = returnToken2
                this.needsDOBValidation = false
                // Update local storage
                const priorApplicationResponseData = appSessionStorage.getItem(localStorageKey.priorApplicationFoundResponseJSON)
                const priorApplicationResponse = JSON.parse(priorApplicationResponseData)
                // Add returnToken2 and dismiss the need for confirming dob again
                const updatedPriorApplicationResponse = Object.assign(priorApplicationResponse, { returnToken2: this.returnToken2, needsDOBValidation: this.needsDOBValidation })
                appSessionStorage.setItem(localStorageKey.priorApplicationFoundResponseJSON, JSON.stringify(updatedPriorApplicationResponse))
            },
            onDOBConfirmed: async function (returnToken2) {
                await this.updateLocalStorageInfo(returnToken2)

                // Now that we have returntoken2 try special cases, if any reached do nothing else
                if (await this.handleSpecialCases()) {
                    return
                }

                // Continue prior application
                return this.onContinuePrior()
            },
            onContinueCurrent: async function (returnToken2) {
                await this.updateLocalStorageInfo(returnToken2)

                console.info('User decided to continue new application, redirecting to confirm discard prior application')
                return await this.$router.push({
                    path: sharedPagePaths.CONFIRM_DISCARD_PRIOR_APPLICATION,
                    query: {
                        a: this.pathAfterNextOne,
                        c: this.isCoApplicant,
                    },
                })
            },
        },
    }
</script>
