<template>
    <onboarding-layout
        :loading="loading"
        :loading-title="loadingTitle"
        :error-text="errorText"
    >
        <h5 class="fw-light mb-5">
            <strong>Welcome back!</strong><br>To continue where you left off, please confirm your date of birth.
        </h5>

        <form-container
            id="confirmDOBForm"
            ref="form"
            data-testid="applicant-confirm-dob"
        >
            <form-field-date
                class="mb-2"
                v-model="applicantDOB"
                name="applicantDOB"
                validation-rules="required|dateRule|ageRule"
                :label="$t('components.applicationForm.placeholder.dateOfBirth')"
                :placeholder="$t('components.applicationForm.placeholder.dateOfBirth')"
                :focus-placeholder="$t('components.applicationForm.placeholder.dob')"
            />
            <form-button
                type="button"
                label="Continue"
                class="mt-2 mb-2"
                :submitting="confirmLoading"
                @click="onSubmit"
                event-name="click_button_submit_duplicate_application_dob"
            />
            <form-button
                v-if="!isPriorityApplication"
                type="button"
                label="Start New Application"
                class="mt-2 mb-2"
                :submitting="continueCurrentLoading"
                button-classes="btn btn-secondary"
                @click="onContinueCurrent"
            />
        </form-container>
        <template #sidebar>
            <ul class="list-unstyled list-row">
                <help-list-item />
            </ul>
        </template>
    </onboarding-layout>
</template>

<script>
    import originationMixin from '@/mixins/originationMixin'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import HelpListItem from '@/components/onboarding/HelpListItem'
    import OnboardingLayout from '@/layouts/Onboarding'
    import FormButton from '@/components/base/FormButton'
    import FormContainer from '@/components/base/FormContainer'
    import FormFieldDate from '@/components/base/FormFieldDate'
    import { getApplicantReturnToken2UsingPhoneNumberAndDOB } from '../services/applicantController'

    export default {
        name: 'ApplicantConfirmDob',
        components: {
            HelpListItem,
            OnboardingLayout,
            FormButton,
            FormContainer,
            FormFieldDate,
        },
        props: { nextPath: String, isPriorityApplication: Boolean },
        mixins: [originationMixin],
        data() {
            return {
                applicantDOB: '',
                cachedResponses: {},
                confirmLoading: false,
                continueCurrentLoading: false,
            }
        },
        computed: {
            phoneNumber: () => {
                const phoneNumber = appSessionStorage.getItem(localStorageKey.phoneNumber)
                if (!phoneNumber) {
                    throw new TypeError('No phone number found, we need it to proceed')
                }
                return phoneNumber
            },
        },
        methods: {
            async getReturnToken2() {
                // Check is not already submitting
                if (this.submitting) {
                    return
                }

                this.submitting = true

                // Check if form is valid
                const isValid = await this.$refs.form.$refs.observer.validate()
                if (!isValid) {
                    this.submitting = false
                    return
                }

                if (typeof this.cachedResponses[this.applicantDOB] !== 'undefined') {
                    this.submitting = false
                    return this.cachedResponses[this.applicantDOB]
                }

                try {
                    const returnToken2 = await getApplicantReturnToken2UsingPhoneNumberAndDOB(this.phoneNumber, this.applicantDOB)
                    this.cachedResponses[this.applicantDOB] = returnToken2

                    // If dob is correct continue with the flow
                    if (returnToken2) {
                        logger.info(`DOB is valid, found return token2: ${returnToken2} for phoneNumber: ${this.phoneNumber}`)
                        return returnToken2
                    } else {
                        logger.log(`Invalid DOB: ${this.applicantDOB} for phone number: ${this.phoneNumber}`)
                        this.errorText = 'Sorry, the Date Of Birth does not match the previous application.'
                    }
                } finally {
                    this.submitting = false
                }
            },
            async onSubmit() {
                this.confirmLoading = true
                const returnToken2 = await this.getReturnToken2()
                if (returnToken2) {
                    this.$emit('dobconfirmed', returnToken2)
                }
                this.confirmLoading = false
            },
            async onContinueCurrent() {
                this.continueCurrentLoading = true
                const returnToken2 = await this.getReturnToken2()
                if (returnToken2) {
                    this.$emit('continuecurrent', returnToken2)
                }
                this.continueCurrentLoading = false
            },
        },
    }
</script>

<style scoped></style>
